.register {
    height: 100%;
    width: 100%;
    display:flex;
}

h1 {
    text-shadow: -2px 1px rgba(black, 0.1);
}

.register .center {
    margin-top: 128px;
    width: 80%;
    text-align: center;
}

form {
    margin: auto;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    width: 20rem;
}


.register .buttonContainer {
    margin-top: 82px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}